import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ROUTES } from 'utils/constants';

import PrivateRoute from 'components/PrivateRoute';
import Login from 'views/Login';
// import Signup from '../../views/Signup';
import Onboarding from 'views/Onboarding';
import Dashboard from 'views/Dashboard';
import Plans from 'views/Plans';
import CreatePlan from 'views/CreatePlan';
import CreatePatient from 'views/CreatePatient';
import Patients from 'views/Patients';
import Plan from 'views/Plan';
import Settings from 'views/Settings';
import EditProfile from 'views/EditProfile';
import Billing from 'views/Billing';
import Library from 'views/Library';
import Terms from 'views/Terms';
import License from 'views/License';
import Privacy from 'views/Privacy';
import Covenants from 'views/Covenants';
import Events from 'views/Events';
import Forms from 'views/Forms';
import Calendar from 'views/Calendar';
import CreateForm from 'views/CreateForm';
import Surveys from 'views/Surveys';
import NewUser from 'views/NewUser';
import PasswordRecovery from 'views/PasswordRecovery';
import Reports from 'views/Reports';
import Employees from 'views/Employees';
import Rewards from 'views/Rewards';
import NewHome from 'views/NewHome';
import NewEmployee from 'views/NewEmployee';
import HowToWin from 'views/HowToWin';
import SetupLanguage from 'components/SetupLanguage';
import Templates from 'views/Templates';
import CreateTemplate from 'views/CreateTemplate';
import Readings from 'views/Readings';
import CreateReading from 'views/CreateReading';
import Orders from 'views/Orders';
import RedeemProduct from 'views/RedeemProduct';
import CreateProgram from 'views/CreateProgram';
import Programs from 'views/Programs';
import CreateCompany from 'views/CreateCompany';
import Companies from 'views/Companies';
import CreateNotification from 'views/CreateNotification';
import Chat from 'views/Chat';
import Availability from 'views/Availability';
import ActivePauses from 'views/ActivePauses';
import CreateActivePause from 'views/CreateActivePause';
import AudioList from 'views/AudioList';
import CreateAudio from 'views/CreateAudio';
import Invoices from 'views/Invoices';
import ShortVideos from 'views/ShortVideos';
import CreateShortVideo from 'views/CreateShortVideo';
import EviePrograms from 'views/EviePrograms/EviePrograms';
import CreateEvieProgram from 'views/CreateEvieProgram/CreateEvieProgram';
import Squeezes from 'views/Squeezes/Squeezes';
import CreateSqueeze from 'views/CreateSqueeze/CreateSqueeze';
import EvieCheckoutPremium from 'views/EvieCheckoutPremium';
import EvieCheckoutPlus from 'views/EvieCheckoutPlus';
import Individuals from 'views/Individuals';

const Routes = () => (
  <Router>
    <SetupLanguage />
    <Switch>
      <Route exact path="/" component={NewHome} />
      <Route exact path={ROUTES.CLINICS} component={Login} />
      <Route exact path={ROUTES.COMPANIES} component={Login} />
      <Route exact path={ROUTES.SUPER_CLINIC} component={Login} />
      <Route exact path={ROUTES.SPECIALIST} component={Login} />
      <Route exact path="/terminos" component={Terms} />
      <Route exact path="/licencia" component={License} />
      <Route exact path="/privacidad" component={Privacy} />
      <Route exact path="/encuestas" component={Surveys} />
      <Route exact path="/nuevo-usuario/:userToken" component={NewUser} />
      <Route exact path="/reset_password" component={PasswordRecovery} />
      <Route exact path="/nuevo-empleado/:companyToken" component={NewEmployee} />
      <Route exact path="/how-to-win" component={HowToWin} />
      <Route exact path="/redeem-product" component={RedeemProduct} />
      <PrivateRoute exact path="/induccion" component={Onboarding} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/crear-paciente" component={CreatePatient} />
      <PrivateRoute exact path={ROUTES.EDIT_PATIENT} component={CreatePatient} />
      <PrivateRoute exact path="/pacientes" component={Patients} />
      <PrivateRoute exact path="/pacientes/:id" component={Patients} />
      <PrivateRoute exact path="/individuals" component={Individuals} />
      <PrivateRoute exact path="/individuals/:id" component={Individuals} />
      <PrivateRoute exact path="/planes" component={Plans} />
      <PrivateRoute exact path="/reportes" component={Reports} />
      <PrivateRoute exact path="/convenios" component={Covenants} />
      <PrivateRoute exact path="/eventos" component={Events} />
      <PrivateRoute exact path="/plan" component={Plan} />
      <PrivateRoute exact path="/crear-plan" component={CreatePlan} />
      <PrivateRoute exact path="/editar-plan" component={CreatePlan} />
      <PrivateRoute exact path="/configuracion" component={Settings} />
      <PrivateRoute exact path="/billing" component={Billing} />
      <PrivateRoute exact path="/biblioteca" component={Library} />
      <PrivateRoute exact path="/formularios" component={Forms} />
      <PrivateRoute exact path="/crear-formulario" component={CreateForm} />
      <PrivateRoute exact path="/formulario" component={CreateForm} />
      <PrivateRoute exact path={ROUTES.CALENDAR} component={Calendar} />
      <PrivateRoute exact path={ROUTES.AVAILABILITY} component={Availability} />
      <PrivateRoute exact path="/editar-perfil" component={EditProfile} />
      <PrivateRoute exact path="/empleados" component={Employees} />
      <PrivateRoute exact path="/recompensas" component={Rewards} />
      <PrivateRoute exact path="/orders" component={Orders} />
      <PrivateRoute exact path="/plantillas" component={Templates} />
      <PrivateRoute exact path="/crear-plantilla" component={CreateTemplate} />
      <PrivateRoute exact path="/plantilla" component={CreateTemplate} />
      <PrivateRoute exact path={ROUTES.READINGS} component={Readings} />
      <PrivateRoute exact path={ROUTES.READING} component={CreateReading} />
      <PrivateRoute exact path={ROUTES.CREATE_READING} component={CreateReading} />
      <PrivateRoute exact path={ROUTES.PROGRAM} component={CreateProgram} />
      <PrivateRoute exact path={ROUTES.CREATE_PROGRAM} component={CreateProgram} />
      <PrivateRoute exact path={ROUTES.PROGRAMS} component={Programs} />
      <PrivateRoute exact path={ROUTES.COMPANY} component={CreateCompany} />
      <PrivateRoute exact path={ROUTES.CREATE_COMPANY} component={CreateCompany} />
      <PrivateRoute exact path={ROUTES.COMPANIES_LIST} component={Companies} />
      <PrivateRoute exact path={ROUTES.CREATE_NOTIFICATION} component={CreateNotification} />
      <PrivateRoute exact path={ROUTES.CHAT} component={Chat} />
      <PrivateRoute exact path={ROUTES.ACTIVE_PAUSES} component={ActivePauses} />
      <PrivateRoute exact path={ROUTES.CREATE_ACTIVE_PAUSE} component={CreateActivePause} />
      <PrivateRoute exact path={ROUTES.ACTIVE_PAUSE} component={CreateActivePause} />
      <PrivateRoute exact path={ROUTES.AUDIO_LIST} component={AudioList} />
      <PrivateRoute exact path={ROUTES.AUDIO} component={CreateAudio} />
      <PrivateRoute exact path={ROUTES.CREATE_AUDIO} component={CreateAudio} />
      <PrivateRoute exact path={ROUTES.INVOICES} component={Invoices} />
      <PrivateRoute exact path={ROUTES.SHORT_VIDEOS} component={ShortVideos} />
      <PrivateRoute exact path={ROUTES.SHORT_VIDEO} component={CreateShortVideo} />
      <PrivateRoute exact path={ROUTES.CREATE_SHORT_VIDEO} component={CreateShortVideo} />
      <PrivateRoute exact path={ROUTES.EVIE_PROGRAMS} component={EviePrograms} />
      <PrivateRoute exact path={ROUTES.EVIE_PROGRAM} component={CreateEvieProgram} />
      <PrivateRoute exact path={ROUTES.CREATE_EVIE_PROGRAM} component={CreateEvieProgram} />
      <PrivateRoute exact path={ROUTES.SQUEEZES} component={Squeezes} />
      <PrivateRoute exact path={ROUTES.SQUEEZE} component={CreateSqueeze} />
      <PrivateRoute exact path={ROUTES.CREATE_SQUEEZE} component={CreateSqueeze} />
      {/* <Route exact path="/registro" component={Signup} /> */}
      <Route exact path="/join-evie-premium" component={EvieCheckoutPremium} />
      <Route exact path="/join-evie-plus" component={EvieCheckoutPlus} />
    </Switch>
  </Router>
);

export default Routes;
